<template>
  <div>
    <header class="el-drawer__header nanw_khan">
      <span title="Новый заказ">{{ $t('message.new_groups') }}</span>
      <el-button
        type="success"
        size="small"
        class="mr-1"
        :loading="loadingButton"
        :disabled="loadingButton"
        @click="submit()"
      >{{ $t('message.save') }}</el-button>
         <el-button :loading="loadingButton"
        :disabled="loadingButton" type="primary" size="small" class="mr-1" @click="submit(true)">{{ $t('message.save_and_close') }}</el-button>
                    
      <el-button type="warning" icon="el-icon-close" size="small" @click="close">{{ $t('message.close') }}</el-button>
    </header>
    <div class="body__modal">
      <el-form ref="form" :model="form" size="small" class="aticler_m stylekhanForm">
        <el-row :gutter="20">
          <el-col :sm="12">
            <el-form-item :label="columns.name.title">
              <el-input v-model="form.name" :placeholder="columns.name.title" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :sm="12">
            <el-form-item :label="columns.parent.title">
              <categories
                v-model="form.parent_id"
                :category_id="form.id ? form.parent ? form.parent.id : form.parent_id : form.parent_id"
              ></categories>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawerChild from "@/utils/mixins/drawer-child";
import form from "@/utils/mixins/form";
import categories from "@/components/inventory-select/categories";

export default {
  mixins: [drawerChild, form],
  components: {
    categories,
  },
  computed: {
    ...mapGetters({
      columns: "categories/columns",
      model: "categories/model",
      rules: "categories/rules",
    }),
  },
  methods: {
    ...mapActions({
      save: "categories/store",
      update: "categories/update",
      show: "categories/show",
      empty: "categories/empty",
    }),

    submit(resetForm = false) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.loadingButton = true;
          console.log(this.form);          
          if (this.form.id == null) {
            this.save(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.form = res.data.category;
                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              })
              .catch((err) => {
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
                this.loadingButton = false;
              })
              .finally(() => {
                  if (resetForm) {
                    this.$refs["form"].resetFields();
                    this.close();
                    this.empty();
                    this.parent().listChanged();
                  }
              });
          } else {
            this.update(this.form)
              .then((res) => {
                this.loadingButton = false;
                this.$notify({
            title: 'Успешно',
            type: "success",
            offset: 130,
            message: res.message
          });
              })
              .catch((err) => {
                this.loadingButton = false;
                this.$notify({
        title: 'Ошибка',
        type: "error",
        offset: 130,
        message: err.error.message
      });
              })
              .finally(() => {
                if (resetForm) {
                  this.$refs["form"].resetFields();
                  this.close();
                  this.empty();
                  this.parent().listChanged();
                }
              });
          }
        }
      });
    },
  },
};
</script>
